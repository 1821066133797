import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Bayout from "../../components/blogLayout"
import BlogListItem from "../../components/blogListItem"
import { removeTrailingSlash } from "../../utils/gatsby-node-helpers"

export default ({ data }) => {
  const [ firstPost, ...restPosts ] = data.allWordpressPost.edges;

  const featuredPost = firstPost.node;
  const featuredPostImage = (featuredPost.featured_media)
    ? <img src={featuredPost.featured_media.source_url} alt="" className="img-fluid mb-3 mb-md-4" />
    : "";
  const featuredPostLink = removeTrailingSlash('/blog' + decodeURI(featuredPost.path));

  return (
    <Bayout>
      <div className="d-flex flex-column">
        <div className="blog-section-title">Featured</div>
        <div className="blog-featured mb-5">
          <Link to={featuredPostLink}>
            {featuredPostImage}
            <div className="blog-featured-title text-inter mb-3" dangerouslySetInnerHTML={{ __html: featuredPost.title }} />
          </Link>
          <div className="blog-featured-excerpt" dangerouslySetInnerHTML={{ __html: featuredPost.excerpt }} />
        </div>

        <div className="blog-section-title">Recent</div>
        <div className="blog-list d-flex flex-column">
          {restPosts.map(({ node }, i) =>
            <Fragment key={node.id}>
              <BlogListItem node={node} />
              {i < restPosts.length - 1 && <hr />}
            </Fragment>
          )}
          <Link to="/blog/page/1" className="blog-list-see-all-link text-inter">
            {"See all ->"}
          </Link>
        </div>
      </div>
    </Bayout>
  )
}

export const query = graphql`
  query($limit:Int!, $skip:Int!) {
    allWordpressPost(sort: {fields: [date], order: [DESC]}, limit: $limit, skip: $skip, filter: {categories: {elemMatch: {name: {ne: "BTT Weekly Update"}}}}){
      edges {
        node {
          title
          excerpt
          path
          id
          date(formatString: "YYYY/MM/DD")
          categories {
            name
            path
          }
          featured_media {
          	source_url
         }
        }
      }
    }
  }
`
